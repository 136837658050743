import { useEffect, useState, useCallback, Suspense } from "react";
import { Button } from "@nextui-org/button";
import { Skeleton } from "@nextui-org/skeleton";
import { Link } from "@nextui-org/link";
import { Unavailable } from "@/components/custom/Unavailable";
import { LazyIframe } from "@/components/common/modal/ModalLazyIframe";
import { MdiClose } from "@/assets/icons/iconify/mdi-close";
import { MdiWeb } from "@/assets/icons/iconify/mdi-web";

type ProjectModalProps = {
  onClose: () => void;
  link: string;
  name: string;
  available: boolean;
};

export const ProjectModal = ({
  onClose,
  link,
  name,
  available,
}: ProjectModalProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [handleEsc]);

  const handleIframeLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm transition-all duration-300 ease-in-out"
      onClick={onClose}
    >
      <div className="relative" onClick={(e) => e.stopPropagation()}>
        <Button
          className="absolute -right-4 -top-4 bg-indigo-500 bg-opacity-90 p-2 text-white transition-all duration-200 ease-in-out hover:bg-opacity-75"
          onClick={onClose}
          isIconOnly
          radius="full"
          title={"close"}
        >
          <MdiClose className="h-6 w-6 text-white" />
        </Button>
        {available ? (
          <>
            {isLoading && <Skeleton className="h-[80vh] w-[80vw]" />}
            <Suspense fallback={<Skeleton className="h-[80vh] w-[80vw]" />}>
              <LazyIframe
                src={link}
                title="Project"
                className={`h-[80dvh] w-[90dvw] md:w-[80dvw] ${
                  isLoading ? "hidden" : "block"
                }`}
                onLoad={handleIframeLoad}
              />
            </Suspense>
            <div className="mt-4 flex justify-between">
              <Link
                href={link}
                isExternal
                rel="noopener noreferrer"
                className="flex items-center text-blue-500 hover:underline"
              >
                <MdiWeb className="mr-2 h-6 w-6" />
                Visiter le site
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="h-[80vh] w-[80vw] rounded-xl bg-secondary">
              <Unavailable siteName={name} siteLink={link} />
            </div>
            <div className="mt-4 flex justify-between">
              <Link
                href={link}
                isExternal
                rel="noopener noreferrer"
                className="flex items-center text-blue-500 hover:underline"
              >
                <MdiWeb className="mr-2 h-6 w-6" />
                Visiter le site
              </Link>
            </div>
          </>
        )}
        <h2 className="mt-4 text-center text-xl font-bold uppercase">{name}</h2>
      </div>
    </div>
  );
};
