"use client";

import { Button } from "@nextui-org/button";
import { PulsatingButton } from "@/components/common/button/ButtonPulsating";
import Link from "next/link";

type PrimaryButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  children: React.ReactNode;
  to?: string;
  calendly?: boolean;
  pulsating?: boolean;
  colorClassName?: string;
};

export const PrimaryButton = ({
  onClick,
  className,
  children,
  to,
  calendly = false,
  pulsating = false,
  colorClassName = "bg-primary text-white hover:bg-black hover:text-white",
}: PrimaryButtonProps) => {
  const baseClassName =
    "p-3 text-sm font-[500] duration-300 ease-in-out hover:!opacity-100";

  if (calendly) {
    return (
      <PulsatingButton
        className={`${baseClassName} ${className}`}
        onClick={onClick}
        calendly={calendly}
        pulsating={pulsating}
        colorClassName={colorClassName}
      >
        {children}
      </PulsatingButton>
    );
  }

  if (to) {
    return (
      <Button
        className={`${baseClassName} ${colorClassName} ${className}`}
        radius="sm"
        onClick={onClick}
        as={Link}
        href={to}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      className={`${baseClassName} ${colorClassName} ${className}`}
      radius="sm"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
