import { PrimaryButton } from "@/components/common/button/ButtonPrimary";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { useMediaQuery } from "@/hooks/use-media-query";
import { MaterialSymbolsLightCheckCircleOutline } from "@/assets/icons/iconify/material-symbols-light-check-circle-outline";

import { Loader } from "@/components/custom/Loader";
import { lazy, Suspense } from "react";

const DotLottieReact = lazy(() =>
  import("@lottiefiles/dotlottie-react").then((module) => ({
    default: module.DotLottieReact,
  })),
);

export const StartProjectSection = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");

  const languageFeatures = [
    {
      title: "Objectifs clairs et personnalisés",
      description:
        "Notre équipe identifie vos besoins pour créer une stratégie sur-mesure et efficace.",
    },
    {
      title: "Plan d’action concret",
      description:
        "Repartez avec une feuille de route claire et actionnable pour booster votre croissance.",
    },
    {
      title: "Résolution de vos blocages",
      description:
        "Identifiez vos points faibles et obtenez des solutions adaptées pour avancer sereinement.",
    },
    {
      title: "Partenariat gagnant-gagnant",
      description:
        "Nous construisons une relation durable pour maximiser votre succès à long terme.",
    },
    {
      title: "Un impact immédiat",
      description:
        "Dès le premier contact, vous verrez la différence dans notre approche et nos solutions.",
    },
  ];

  return (
    <SectionLayout
      className="flex flex-col items-center px-4 py-20 md:px-8"
      id="start-project"
    >
      <div className="flex flex-col items-center text-center">
        <h2 className="text-2xl md:text-4xl">
          Passez à l&apos;action pour transformer votre présence en ligne
        </h2>
        <p className="mt-2 text-gray-400">
          Réservez un rendez-vous visio ou téléphonique avec notre équipe
        </p>
        <PrimaryButton className="mt-4" calendly>
          Je démarre mon projet maintenant 🚀
        </PrimaryButton>
      </div>

      <div className="relative mx-auto mt-16">
        <div className="relative rounded-xl bg-gradient-to-br from-[#7D7D95] to-[#4C4C95] p-[1px]">
          <div className="relative flex flex-col rounded-xl bg-[#393966] p-6 lg:p-14">
            {!isLargeScreen && (
              <div className="mb-8 flex justify-center">
                <Suspense fallback={<Loader />}>
                  <DotLottieReact
                    src="/lottie/start.json"
                    autoplay
                    loop
                    className="h-40 w-40"
                  />
                </Suspense>
              </div>
            )}

            <div>
              <h4 className="text-xl font-semibold md:text-2xl">
                Élevons votre marque, réservez maintenant
              </h4>

              <div className="mt-8">
                <div className="flex flex-col gap-6">
                  {languageFeatures.map((feature, index) => (
                    <div key={index} className="flex gap-3">
                      <MaterialSymbolsLightCheckCircleOutline className="h-6 w-6 text-indigo-500" />
                      <div className="flex-1">
                        <h5 className="font-medium">{feature.title}</h5>
                        <p className="mt-1 text-sm text-gray-400">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLargeScreen && (
          <div
            className="pointer-events-none absolute bottom-0 right-0 translate-x-16 translate-y-16"
            style={{ zIndex: 10 }}
          >
            <Suspense fallback={<Loader />}>
              <DotLottieReact
                src="/lottie/start.json"
                autoplay
                loop
                className="h-40 w-40"
              />
            </Suspense>
          </div>
        )}
      </div>
    </SectionLayout>
  );
};
