import { SectionLayout } from "@/components/layout/SectionLayout";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { Spacer } from "@nextui-org/react";
import { CardCaseStudy } from "@/components/common/card/CardCaseStudy";
import { caseStudiesData } from "@/utils/Constants/CaseStudies";

export const CaseStudySection = () => {
  const firstCaseStudy = caseStudiesData[0];

  return (
    <SectionLayout id="case-studies">
      <div className="flex w-full flex-col text-center">
        <TextSubtitle>Avant/Après</TextSubtitle>
        <TextSubtitle>Une transformation en chiffres</TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          Des résultats qui ne laissent de place au doute.
        </p>
      </div>

      <CardCaseStudy
        clientLogo={firstCaseStudy.clientLogo}
        before={firstCaseStudy.before}
        after={firstCaseStudy.after}
        problem={firstCaseStudy.problem}
        formulas={firstCaseStudy.formulas}
      />
    </SectionLayout>
  );
};
