"use client";

import { useMediaQuery } from "usehooks-ts";

// components
import { ScrollingBanner } from "@/components/common/display/DisplayScrollingBanner";
// constants
import { logos } from "@/utils/Constants/scrollingLogos";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { AvatarCircles } from "@/components/common/media/AvatarCircles";
import { avatars } from "@/utils/Constants/avatars";
import { RatingDisplay } from "@/components/common/media/ReviewsStars";
import { ShinyCheap } from "@/components/common/cheap/ShinyCheap";
import BlurIn from "@/components/common/typography/TextBlurIn";
import { PrimaryButton } from "@/components/common/button/ButtonPrimary";

export const HeroSection = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <SectionLayout
        noPaddingX
        className="min-h-[calc(100dvh_-_64px)] justify-between overflow-x-hidden pt-24"
      >
        <div className="flex flex-col items-center md:my-7">
          <ShinyCheap className="mb-4">SaaS • E-commerce • Vitrine</ShinyCheap>

          <div className="flex w-full flex-col items-center justify-center px-2 text-center ph:px-24 md:px-48 2xl:px-168">
            <BlurIn
              word={
                <>
                  Doublez votre croissance <br />
                  avec un{" "}
                  <span className="text-black">
                    site web sur-mesure qui attire, convertit et domine Google
                  </span>
                  .
                </>
              }
              className="text-2xl xs:text-3xl ph:text-4xl sm:text-5xl lg:text-7xl"
            />
            <BlurIn
              word={
                "Des solutions web sur-mesure, pensées pour booster votre référencement (top 1) et maximiser vos conversions."
              }
              className="text-md mt-4 font-thin xs:text-lg sm:text-xl lg:text-2xl"
            />
          </div>

          <div className="mt-14 flex flex-col items-center gap-4 md:mb-16 md:flex-row md:items-end lg:mb-0">
            <AvatarCircles avatarUrls={avatars} numPeople={2} />
            <RatingDisplay />
          </div>

          <PrimaryButton
            className="my-16 bg-primary text-lg"
            calendly
            pulsating
          >
            Je lance mon projet maintenant ✨
          </PrimaryButton>
        </div>

        <ScrollingBanner gap={isMobile ? "50px" : "100px"}>
          {Array(2)
            .fill(logos)
            .flat()
            .concat(Array(2).fill(logos).flat())
            .map(({ key, logo }, index) => (
              <div
                key={`${key}-${index}`}
                className="flex max-w-[100px] flex-col items-center justify-center text-white"
              >
                {logo}
              </div>
            ))}
        </ScrollingBanner>
      </SectionLayout>
    </>
  );
};

export default HeroSection;
