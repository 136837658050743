import { Button } from "@nextui-org/button";
import Link from "next/link";
import { cn } from "@/utils/cn";

type SecondaryButtonProps = {
  className?: string;
  children: React.ReactNode;
  to: string;
  colorClassName?: string;
};

export const SecondaryButton = ({
  className,
  children,
  to,
  colorClassName = "border-2 border-primary bg-transparent text-primary hover:bg-primary hover:text-white hover:border-transparent",
}: SecondaryButtonProps) => {
  const baseClassName =
    "p-3 text-sm font-[500] duration-300 ease-in-out hover:!opacity-100";

  if (to) {
    return (
      <Button
        className={cn(baseClassName, colorClassName, className)}
        radius="sm"
        as={Link}
        href={to}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      className={cn(baseClassName, colorClassName, className)}
      radius="sm"
    >
      {children}
    </Button>
  );
};
