import { motion } from "framer-motion";
import { Chip } from "@nextui-org/chip";
import { Tooltip } from "@nextui-org/tooltip";
import { Divider } from "@nextui-org/divider";
import { PrimaryButton } from "@/components/common/button/ButtonPrimary";
import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/card";

import { PricingTier } from "@/utils/types/PricingTypes";
import { CarbonInformation } from "@/assets/icons/iconify/carbon-information";
import { CiCheck } from "@/assets/icons/iconify/ci-check";
import { cn } from "@/utils/cn";
import { FrequencyEnum } from "@/utils/types/PricingTypes";
import { CiAddPlus } from "@/assets/icons/iconify/ci-add-plus";
import { useInView } from "react-intersection-observer";

const MotionCard = motion.create(Card as any);
const MotionChip = motion.create(Chip as any);
const MotionDivider = motion.create(Divider as any);

type PricingCardProps = {
  tier: PricingTier;
  openTooltips: Record<string, boolean>;
  setOpenTooltips: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  tooltipRef: React.MutableRefObject<Record<string, HTMLDivElement | null>>;
  handleTooltipToggle: (tierId: string) => void;
  index: number;
};

export const PricingCard = ({
  tier,
  openTooltips,
  setOpenTooltips,
  tooltipRef,
  handleTooltipToggle,
  index,
}: PricingCardProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardVariants = {
    hidden: {
      y: 100,
      opacity: 0,
      scale: 0.9,
      rotateX: -30,
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      rotateX: 0,
      transition: {
        type: "spring",
        duration: 1,
        delay: index * 0.2,
        bounce: 0.3,
      },
    },
  };

  const chipVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        delay: index * 0.2 + 0.5,
        duration: 0.6,
      },
    },
  };

  const featureVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: index * 0.2 + i * 0.1,
        duration: 0.5,
      },
    }),
  };

  const priceVariants = {
    hidden: { scale: 0.5, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        delay: index * 0.2 + 0.3,
        duration: 0.8,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: index * 0.2 + 0.7,
        duration: 0.5,
      },
    },
  };

  return (
    <MotionCard
      ref={ref}
      variants={cardVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      key={tier.key}
      className={cn("perspective-1000 relative w-full p-3 tb:w-[380px]", {
        "overflow-visible bg-primary shadow-2xl shadow-primary/20":
          tier.mostPopular,
        "!border-medium border-primary bg-transparent lg:mt-12":
          !tier.mostPopular,
      })}
      shadow="none"
    >
      {tier.mostPopular && (
        <MotionChip
          variants={chipVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          classNames={{
            base: "absolute -top-3 left-[28%] -translate-x-[28%] bg-primary-foreground shadow-large border-medium border-primary w-fit mx-auto",
            content: "font-medium text-primary whitespace-nowrap",
          }}
          color="primary"
        >
          Offre la plus populaire
        </MotionChip>
      )}

      <Tooltip
        content={
          "Les devis peuvent être fait sur mesure, en achat, location / abonnement."
        }
        color="primary"
        placement="bottom"
        className={cn("w-[200px]", {
          "bg-white text-black": tier.mostPopular,
        })}
        isOpen={openTooltips[tier.key]}
        onOpenChange={(isOpen) =>
          setOpenTooltips((prev) => ({ ...prev, [tier.key]: isOpen }))
        }
      >
        <div
          ref={(el) => {
            tooltipRef.current[tier.key] = el;
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleTooltipToggle(tier.key);
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            handleTooltipToggle(tier.key);
          }}
          onMouseEnter={() => {
            if (window.innerWidth > 767) {
              setOpenTooltips((prev) => ({
                ...prev,
                [tier.key]: true,
              }));
            }
          }}
          onMouseLeave={() => {
            if (window.innerWidth > 767) {
              setOpenTooltips((prev) => ({
                ...prev,
                [tier.key]: false,
              }));
            }
          }}
          className="absolute right-4 top-4 z-50 cursor-pointer"
          style={{ cursor: "pointer" }}
        >
          <CarbonInformation className="w-5" />
        </div>
      </Tooltip>

      <CardHeader className="flex flex-col items-start gap-2 pb-6">
        <motion.h2
          variants={featureVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className={cn("text-lg font-medium", {
            "text-primary-foreground": tier.mostPopular,
          })}
        >
          {tier.nameKey}
        </motion.h2>

        <motion.p
          variants={featureVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className={cn("text-medium text-default-500", {
            "text-primary-foreground/70": tier.mostPopular,
          })}
        >
          Sur mesure, sans engagement.
        </motion.p>
      </CardHeader>

      <MotionDivider
        variants={featureVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="bg-primary-foreground/20"
      />

      <CardBody className="justify-between overflow-hidden">
        <div className="flex flex-col gap-8">
          <motion.p
            variants={priceVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="flex max-w-[261px] items-baseline gap-1 overflow-hidden whitespace-nowrap pt-2"
          >
            <span
              className={cn(
                "glitch inline bg-gradient-to-br from-foreground to-foreground-600 bg-clip-text text-3xl font-semibold leading-7 tracking-tight text-transparent",
                {
                  "text-primary-foreground": tier.mostPopular,
                },
              )}
            >
              Sur devis
            </span>
          </motion.p>

          <div className="flex flex-col gap-1">
            <ul className="flex flex-col gap-2">
              {tier.features
                ?.filter((feature) => feature.isAdditional)
                .map((feature, i) => (
                  <motion.li
                    key={feature.labelKey}
                    custom={i}
                    variants={featureVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="flex items-center gap-2"
                  >
                    <CiCheck
                      className={cn("h-6 min-h-6 w-6 min-w-6 text-primary", {
                        "text-primary-foreground": tier.mostPopular,
                      })}
                    />
                    <motion.div
                      className={cn("text-default-500", {
                        "text-primary-foreground/70": tier.mostPopular,
                      })}
                    >
                      {feature.labelKey}
                    </motion.div>
                  </motion.li>
                ))}
            </ul>
            <div
              className={cn("mr-[32px] flex justify-center", {
                hidden: !tier.features?.filter(
                  (feature) => feature.isAdditional,
                ).length,
              })}
            >
              <CiAddPlus
                className={cn("h-6 w-6 text-primary", {
                  "text-primary-foreground": tier.mostPopular,
                })}
              />
            </div>
            <ul className="flex flex-col gap-2">
              {tier.features
                ?.filter((feature) => !feature.isAdditional)
                .map((feature, i) => (
                  <motion.li
                    key={feature.labelKey}
                    custom={i}
                    variants={featureVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="flex items-center gap-2"
                  >
                    <CiCheck
                      className={cn("h-6 min-h-6 w-6 min-w-6 text-primary", {
                        "text-primary-foreground": tier.mostPopular,
                      })}
                    />
                    <motion.div
                      className={cn("text-default-500", {
                        "text-primary-foreground/70": tier.mostPopular,
                      })}
                    >
                      {feature.labelKey}
                    </motion.div>
                  </motion.li>
                ))}
            </ul>
          </div>
        </div>
        {tier.bonus && (
          <div className="mt-4">
            <h4
              className={cn("mb-2 font-semibold", {
                "text-primary-foreground": tier.mostPopular,
              })}
            >
              {"pricing_additional_options"}
            </h4>
            <ul className="flex flex-col gap-2">
              {tier.bonus.map((bonus, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span
                    className={cn("text-default-500", {
                      "text-primary-foreground/70": tier.mostPopular,
                    })}
                  >
                    {bonus.labelKey}
                  </span>
                  <span
                    className={cn("glitch font-semibold", {
                      "text-primary-foreground": tier.mostPopular,
                    })}
                    data-value={bonus.price[FrequencyEnum.OneTime]}
                  >
                    {bonus.price[FrequencyEnum.OneTime]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </CardBody>

      <CardFooter>
        <PrimaryButton
          calendly
          className="w-full"
          colorClassName={cn({
            "bg-primary-foreground text-primary hover:bg-black hover:text-primary":
              tier.mostPopular,
            "bg-primary text-white hover:bg-black hover:text-white":
              !tier.mostPopular,
          })}
        >
          {tier.buttonTextKey}
        </PrimaryButton>
      </CardFooter>
    </MotionCard>
  );
};
