import { ButtonComponent } from "@/components/common/button/ButtonComponent";

type UnavailableProps = {
  siteName: string;
  siteLink: string;
};

export const Unavailable = ({ siteName, siteLink }: UnavailableProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-5 rounded-lg p-4 text-center shadow-lg">
      <h2 className="text-3xl font-bold text-white">
        Prévisualisation Indisponible
      </h2>
      <div>
        <p className="mt-2 max-w-2xl text-lg text-gray-300">
          Le site {siteName} n&apos;est pas disponible en prévisualisation sur
          notre site.
        </p>
        <p className="mt-2 max-w-2xl text-lg text-gray-300">
          Vous pouvez cependant visiter le site directement en cliquant sur le
          bouton ci-dessous.
        </p>
      </div>
      <ButtonComponent href={siteLink}>Visiter le site</ButtonComponent>
    </div>
  );
};
