"use client";

import { useEffect, useState, useRef } from "react";
import { Spacer } from "@/components/custom/Spacer";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { reviewsData } from "@/utils/Constants/reviewsData";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { motion } from "framer-motion";
import type { Review } from "@/utils/Constants/reviewsData";
import {
  CardReviewContainer,
  ReviewCard,
  type ReviewCardProps,
} from "@/components/common/card/CardReview";

export const ReviewsSection = () => {
  return (
    <SectionLayout noPaddingX id="avis" className="overflow-hidden">
      <div className="flex w-full flex-col px-4 text-center sm:px-8 md:px-24 lg:px-48">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <TextSubtitle>Ils nous font confiance</TextSubtitle>
        </motion.div>
        <Spacer y={2} />
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-medium text-default-500 sm:text-large"
        >
          Des retombées positives qui témoignent de notre engagement et de notre
          qualité.
        </motion.p>
      </div>

      <Spacer y={6} />

      <div className="relative w-full">
        <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-background to-transparent sm:w-24 md:w-32" />

        <div className="w-full">
          <CardReviewContainer className="[--duration:70s]">
            {reviewsData.map((review: Review, index: number) => (
              <ReviewCard
                key={`${review.name}-${index}`}
                avatar={review.image.src}
                name={review.name}
                role={review.role}
                content={review.content}
                index={index}
              />
            ))}
          </CardReviewContainer>
        </div>

        <div className="pointer-events-none absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-background to-transparent sm:w-24 md:w-32" />
      </div>
    </SectionLayout>
  );
};
