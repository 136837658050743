import { Button } from "@nextui-org/button";

type ButtonComponentProps = {
  icon?: never;
  href?: string;
  positionIcon?: never;
  children: string;
  className?: string;
  onClick?: () => void;
};

export const ButtonComponent = ({
  children,
  className,
  onClick,
  href,
}: ButtonComponentProps) => {
  return (
    <Button
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={
        className +
        "mt-4 rounded-lg border border-white bg-blue-600 px-6 py-3 text-white transition-colors hover:bg-blue-700"
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
