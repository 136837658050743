import { SectionLayout } from "@/components/layout/SectionLayout";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { CardImpact } from "@/components/common/card/CardImpact";
import { useImpactData } from "@/utils/Constants/Impact";
import { Spacer } from "@nextui-org/react";

export const ImpactSection = () => {
  const { impactData } = useImpactData();

  return (
    <SectionLayout id="impact">
      <div className="flex w-full flex-col text-center">
        <TextSubtitle>Votre réussite est notre mission</TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          Découvrez les avantages concrets que nous offrons.
        </p>
        <div className="mt-12 grid w-full auto-rows-fr grid-cols-1 gap-10 sm:grid-cols-2 xl:grid-cols-4">
          {impactData.map((impact, index) => (
            <CardImpact
              key={impact.title}
              icon={<impact.icon />}
              title={impact.title}
              description={impact.content}
              delay={index * 0.25}
            />
          ))}
        </div>
      </div>
    </SectionLayout>
  );
};
