import { Spacer } from "@/components/custom/Spacer";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { LucidePlus } from "@/assets/icons/iconify/lucile-plus";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { useFaqData } from "@/utils/Constants/FAQ";

export const FaqSection = () => {
  const FAQ = useFaqData();

  return (
    <SectionLayout id="team" className="gap-60">
      <div className="flex w-full flex-col text-center">
        <TextSubtitle>FAQ</TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          Vous avez des questions ? Nous avons les réponses qu&apos;il vous
          faut.
        </p>
      </div>
      <Accordion
        keepContentMounted
        itemClasses={{
          base: "px-0 md:px-2 md:px-6",
          title: "font-medium",
          trigger: "py-6 flex-row-reverse",
          content: "pt-0 pb-6 text-base text-default-500",
          indicator: "rotate-0 data-[open=true]:-rotate-45",
        }}
        items={FAQ}
        className="mx-auto w-full max-w-6xl px-2 ph:px-6 lg:px-8"
      >
        {FAQ.map((item, i) => (
          <AccordionItem
            key={i}
            indicator={<LucidePlus className="w-6 text-indigo-500" />}
            title={item.title}
          >
            {item.content}
          </AccordionItem>
        ))}
      </Accordion>
    </SectionLayout>
  );
};
