import { Spacer } from "@/components/custom/Spacer";
import { SectionLayout } from "@/components/layout/SectionLayout";

import mael from "@/assets/images/team/mael.webp";
import meymoun from "@/assets/images/team/meymoun.png";
import alexandre from "@/assets/images/team/alexandre.webp";

import type { TeamMember } from "@/utils/Constants/aboutData";
import { TeamMemberCard } from "@/components/common/card/CardTeamMember";
import { PrimaryButton } from "@/components/common/button/ButtonPrimary";
import { SecondaryButton } from "@/components/common/button/ButtonSecondary";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";

export const FoundersSection = () => {
  const teamMembers: TeamMember[] = [
    {
      name: "Alexandre Calonne",
      avatar: alexandre,
      role: "Co-fondateur",
      social: {
        linkedin: "https://www.linkedin.com/in/alexandre-calonne/",
        github: "https://github.com/alexandre95calonne",
      },
    },
    {
      name: "Meymoun Boualaoui",
      avatar: meymoun,
      role: "Co-fondateur",
      social: {
        linkedin:
          "https://www.linkedin.com/in/meymoun-boualaoui-expert-site-web/",
        github: "https://github.com/MeymounB",
      },
    },
    {
      name: "Mael Damotte Da Silva",
      avatar: mael,
      role: "Co-fondateur",
      social: {
        linkedin: "https://www.linkedin.com/in/mael-damotte-da-silva/",
        github: "https://github.com/CarieLeon",
      },
    },
  ];

  return (
    <SectionLayout id="team" className="gap-60">
      <div className="w-full py-20">
        <div className="flex w-full flex-col text-center">
          <h3 className="font-medium text-primary">
            Rejoignez une équipe qui fait la différence
          </h3>
          <TextSubtitle>Les artisans de votre succès digital</TextSubtitle>
          <Spacer y={4} />
          <div className="flex w-full justify-center gap-2">
            <SecondaryButton to="/recrutement">
              Voir nos offres d&apos;emploi
            </SecondaryButton>

            <PrimaryButton to="/a-propos">
              Rencontrer l&apos;équipe
            </PrimaryButton>
          </div>
          <Spacer y={8} />
        </div>
        <div className="flex flex-wrap justify-center gap-8">
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} {...member} />
          ))}
        </div>
        <div className="flex w-full flex-col items-center">
          <Spacer y={8} />
          <p className="w-full max-w-5xl text-center text-large text-default-500">
            Chez Next Generation, chaque membre joue un rôle clé pour propulser
            vos projets au sommet. Experts, stratèges et créateurs, nous
            partageons une mission commune : faire de votre site une machine à
            performer.
          </p>
        </div>
      </div>
    </SectionLayout>
  );
};
