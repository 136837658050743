import MonDressingColore from "@/assets/images/clients/logo-mon-dressing-colore.webp";

interface CaseStudyData {
  id: number;
  title: string;
  clientLogo: string;
  before: number;
  after: number;
  problem: string;
  formulas: string[];
}

export const caseStudiesData: CaseStudyData[] = [
  {
    id: 1,
    title: "Mon Dressing Colore",
    clientLogo: MonDressingColore.src,
    before: 2300,
    after: 9429,
    problem:
      "Mon Dressing Coloré, une boutique de vêtements, n’avait pas de site web. Son activité stagnait et les ventes dépendaient uniquement des clients physiques. L’avenir semblait incertain et sans réelle stratégie digitale pour se développer.",
    formulas: ["Formule e-commerce", "Accompagnement réseaux sociaux"],
  },
];
