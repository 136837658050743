import { useRef, useEffect, useState, useMemo } from "react";
import { processSteps } from "@/utils/Constants/processSteps";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { ProcessCard } from "@/components/common/card/CardProcess";
import { Spacer } from "@/components/custom/Spacer";

export const ProcessSection = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const beamRef = useRef<HTMLDivElement>(null);
  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 },
    );

    const h2Elements = sectionRef.current?.querySelectorAll("h2");
    h2Elements?.forEach((h2) => observer.observe(h2));

    return () => {
      h2Elements?.forEach((h2) => observer.unobserve(h2));
    };
  }, []);

  useEffect(() => {
    const updateBeam = () => {
      if (timelineRef.current && beamRef.current) {
        const { top, bottom, height } =
          timelineRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && bottom > 0) {
          const progress = (windowHeight - top) / (height + windowHeight);
          beamRef.current.style.height = `${progress * 102}%`;
        }
      }

      requestAnimationFrame(updateBeam);
    };

    updateBeam();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderedSteps = useMemo(() => {
    return processSteps.map((step, index) => (
      <div
        key={index}
        className={`relative mb-24 flex w-full ${
          isWideScreen && step.side === "left"
            ? "md:justify-start"
            : "justify-end md:justify-end"
        }`}
      >
        {/* <Suspense
          fallback={
            <div className="h-64 w-full animate-pulse rounded-xl bg-gray-700 md:w-[45%]"></div>
          }
        > */}
        <ProcessCard step={step} index={index} isWideScreen={isWideScreen} />

        <div
          className={`absolute h-4 w-4 rounded-full bg-white ${isWideScreen ? "left-1/2 -translate-x-1/2" : "left-4 -translate-x-[7px]"} -translate-y-1/2`}
        ></div>
      </div>
    ));
  }, [isWideScreen]);

  return (
    <SectionLayout id="processus" className="gap-48">
      <div className="mb-6 flex w-full flex-col text-center">
        <TextSubtitle>Comment créons-nous votre projet ?</TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          5 étapes clés pour une transformation digitale réussie.
        </p>
      </div>

      <div
        ref={timelineRef}
        className="relative flex w-full flex-col pt-32 lg:max-w-[1024px] 2xl:max-w-[70vw]"
      >
        <div
          className={`absolute top-0 h-full w-0.5 bg-black ${isWideScreen ? "left-1/2 -translate-x-1/2" : "left-4"}`}
        >
          <div
            ref={beamRef}
            className="absolute top-0 w-full bg-gradient-to-b from-white to-primary"
            style={{ height: "0%" }}
          ></div>
        </div>
        {renderedSteps}
      </div>
    </SectionLayout>
  );
};
