type Step = {
  titleKey: string;
  side: "left" | "right";
  lottieSrc?: string;
};

export const processSteps: Step[] = [
  {
    titleKey:
      "Un rendez-vous stratégique pour comprendre vos objectifs et poser les bases de votre succès en ligne.",
    side: "left",
    lottieSrc: "/lottie/organisation.json",
  },
  {
    titleKey:
      "En moins de 24h, vous recevez un plan d'action détaillé, taillé pour répondre à vos besoins spécifiques.",
    side: "right",
    lottieSrc: "/lottie/clock.json",
  },
  {
    titleKey:
      "Une fois validé, notre équipe démarre le projet avec une méthodologie orientée résultats.",
    side: "left",
    lottieSrc: "/lottie/dev.json",
  },
  {
    titleKey:
      "Des points réguliers pour garantir que chaque étape est alignée avec vos attentes",
    side: "right",
    lottieSrc: "/lottie/followback.json",
  },
  {
    titleKey:
      "Votre site est prêt à performer. Une plateforme qui attire, engage et convertit dès le premier jour.",
    side: "left",
    lottieSrc: "/lottie/launch.json",
  },
];
