"use client";

import { motion, useInView } from "framer-motion";
import { Image } from "@nextui-org/image";
import { cn } from "@/utils/cn";
import { useRef } from "react";

export interface ReviewCardProps {
  avatar: string;
  name: string;
  role: string;
  content: string;
  index: number;
}

export const ReviewCard = (props: ReviewCardProps) => {
  const cardRef = useRef(null);
  const isInView = useInView(cardRef, {
    once: true,
    amount: 0.8, // La carte doit être visible à 80% pour déclencher l'animation
    margin: "100px 0px 0px 0px", // Marge supplémentaire pour déclencher plus tôt
  });

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: props.index * 0.1,
      },
    },
  };

  const imageVariants = {
    hidden: {
      scale: 0,
      rotate: -180,
    },
    visible: {
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: props.index * 0.1 + 0.2,
        bounce: 0.4,
      },
    },
  };

  const textVariants = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: (delay: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: props.index * 0.1 + delay,
      },
    }),
  };

  return (
    <motion.div
      ref={cardRef}
      variants={cardVariants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      className="group relative mx-2 w-[280px] cursor-pointer py-48 sm:mx-4 sm:w-[320px] md:w-[380px]"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <motion.div
        className={cn(
          "relative z-10 h-full overflow-hidden rounded-xl border p-4 sm:p-6",
          "border-gray-50/[.1] bg-gray-50/[.01] backdrop-blur-sm",
          "transition-all duration-300",
          "hover:border-primary/50 hover:bg-gray-50/[.05]",
          "group-hover:shadow-xl group-hover:shadow-primary/10",
        )}
      >
        <div className="flex items-start gap-3 sm:gap-4">
          <motion.div variants={imageVariants}>
            <Image
              className="h-10 w-10 rounded-full sm:h-12 sm:w-12 md:h-[48px] md:w-[48px]"
              alt={props.name}
              src={props.avatar}
            />
          </motion.div>
          <div className="flex flex-col">
            <motion.p
              variants={textVariants}
              custom={0.3}
              className="text-sm font-semibold text-white sm:text-base"
            >
              {props.name}
            </motion.p>
            <motion.p
              variants={textVariants}
              custom={0.4}
              className="text-xs text-gray-400 sm:text-sm"
            >
              {props.role}
            </motion.p>
          </div>
        </div>
        <motion.p
          variants={textVariants}
          custom={0.5}
          className="mt-3 line-clamp-4 text-xs leading-relaxed text-gray-300 sm:mt-4 sm:text-sm"
        >
          {props.content}
        </motion.p>
      </motion.div>
      <div className="absolute inset-0 top-[50%] -z-10 h-[70%] -translate-y-[50%] rounded-xl bg-primary/20 blur-xl transition-all duration-300 group-hover:blur-2xl" />
    </motion.div>
  );
};

export interface CardReviewContainerProps {
  children: React.ReactNode;
  className?: string;
  pauseOnHover?: boolean;
}

export const CardReviewContainer = ({
  children,
  className,
  pauseOnHover = true,
}: CardReviewContainerProps) => {
  return (
    <div
      className={cn(
        "flex w-full overflow-hidden",
        "scroll-smooth [--duration:40s] [--gap:1rem]",
        className,
      )}
    >
      <div
        className={cn("flex animate-infinite-scroll gap-4", {
          "hover:[animation-play-state:paused]": pauseOnHover,
        })}
      >
        {children}
        {children}
        {children} {/* Duplique les enfants pour un défilement continu */}
      </div>
    </div>
  );
};
