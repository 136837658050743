import { useRef, useEffect } from "react";

type TextSubtitleProps = {
  children: React.ReactNode;
  className?: string;
};

export const TextSubtitle = ({ children, className }: TextSubtitleProps) => {
  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (h2Ref.current) {
        const h2Position = h2Ref.current.getBoundingClientRect().top;
        const screenPosition = window.innerHeight;

        if (h2Position < screenPosition) {
          h2Ref.current.style.animationPlayState = "running";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <h2
      ref={h2Ref}
      className={`fade-in-on-scroll text-center text-4xl text-white ph:text-5xl lg:text-7xl ${className || ""}`}
    >
      {children}
    </h2>
  );
};
