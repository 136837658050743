type SpaceValue =
  | 0
  | "px"
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 96;

interface SpacerProps {
  x?: SpaceValue;
  y?: SpaceValue;
  className?: string;
}

const spaceToRem = (space: SpaceValue): string => {
  if (space === "px") return "1px";
  if (space === 0) return "0";
  return `${space * 0.25}rem`;
};

export const Spacer = ({ x, y, className }: SpacerProps): JSX.Element => {
  const style: React.CSSProperties = {
    width: "1px",
    height: "1px",
    marginLeft: x ? spaceToRem(x) : undefined,
    marginTop: y ? spaceToRem(y) : undefined,
  };

  return <span aria-hidden="true" className={className} style={style} />;
};
