"use client";

import HeroSection from "./components/sections/hero/heroSection";
import { ImpactSection } from "./components/sections/ImpactSection";
import { CaseStudySection } from "./components/sections/CaseStudySection";
import { ProjectsSection } from "./components/sections/projectsSection";
import { PricingSection } from "./components/sections/pricingSection";
import { ProcessSection } from "./components/sections/processSection";
import { FoundersSection } from "./components/sections/foundersSection";
import { ReviewsSection } from "./components/sections/reviewsSection";
import { StartProjectSection } from "@/components/startProjectSection";
import { SeoSection } from "./components/sections/seoSection";
import { FaqSection } from "./components/sections/faqSection";
import { ForYouSection } from "./components/sections/forYouSection";

export default function HomePage() {
  return (
    <>
      <main className="flex min-h-screen max-w-[100vw] flex-col items-center justify-between">
        <HeroSection />
        <ImpactSection />
        <ForYouSection />
        <CaseStudySection />
        <ProjectsSection />
        <PricingSection />
        <ProcessSection />
        <FoundersSection />
        <ReviewsSection />
        <StartProjectSection />
        <FaqSection />
        <SeoSection />
      </main>
    </>
  );
}
