"use client";

import React from "react";
import { Spacer } from "@/components/custom/Spacer";

import { SectionLayout } from "@/components/layout/SectionLayout";
import { tiers } from "@/utils/Constants/PricingTiers";
import { PricingCard } from "@/components/common/card/CardPricing";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
// import { PulsatingButton } from "../../../components/common/button/ButtonPulsating";

export const PricingSection = () => {
  const [openTooltips, setOpenTooltips] = React.useState<
    Record<string, boolean>
  >({});
  const tooltipRef = React.useRef<Record<string, HTMLDivElement | null>>({});

  const handleTooltipToggle = (tierId: string) => {
    setOpenTooltips((prev) => ({
      ...prev,
      [tierId]: !prev[tierId],
    }));
  };

  const handleClick = () => {
    window.open("https://wa.me/33663379986", "_blank");
  };

  return (
    <SectionLayout noPaddingX id="tarifs" className="px-2 md:px-8">
      <div className="flex max-w-7xl flex-col px-2 text-center md:px-48">
        <TextSubtitle>
          Des solutions sur-mesure pour transformer votre présence en ligne
        </TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          Deux offres exclusives, entièrement personnalisées, pour révéler le
          potentiel de votre business.
        </p>
      </div>
      <Spacer y={12} />
      <div className="relative px-2">
        <Spacer className="block ph:hidden" y={2} />
        <div className="grid grid-cols-1 gap-4 tb:grid-cols-2">
          {tiers.map((tier, index) => (
            <PricingCard
              key={tier.key}
              tier={tier}
              index={index}
              openTooltips={openTooltips}
              setOpenTooltips={setOpenTooltips}
              tooltipRef={tooltipRef}
              handleTooltipToggle={handleTooltipToggle}
            />
          ))}
        </div>
      </div>

      <Spacer y={4} />
      <div className="flex flex-col items-center gap-4 p-2 ph:gap-2">
        <p className="text-center text-white">
          Paiement unique est payable en 2, 3 ou 4 fois sans frais.
        </p>
        <p className="text-center text-white">
          Vous ne trouvez pas votre bonheur ?&nbsp;
          <button onClick={handleClick} className="text-primary">
            Contactez-nous
          </button>
        </p>
      </div>
    </SectionLayout>
  );
};
