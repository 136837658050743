import React, { useEffect, useCallback, useState } from "react";
import { cn } from "@/utils/cn";
import { motion } from "framer-motion";
import { TablerArrowNarrowRight } from "@/assets/icons/iconify/tabler-arrow-narrow-right";
import { TablerArrowNarrowLeft } from "@/assets/icons/iconify/tabler-arrow-narrow-left";

type CarouselProps = {
  items: JSX.Element[];
  initialScroll?: number;
};

export const Carousel = ({ items, initialScroll = 0 }: CarouselProps) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollability = useCallback(() => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
      checkScrollability();
    }
  }, [initialScroll, checkScrollability]);

  const scroll = useCallback((direction: "left" | "right") => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: direction === "left" ? -300 : 300,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div className="relative w-full max-w-[100vw] overflow-x-hidden">
      <div className="mr-[5%] mt-[2rem] flex items-center justify-end gap-2 md:mt-0">
        <button
          title="Précédent"
          className="relative z-20 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 disabled:opacity-50"
          onClick={() => scroll("left")}
          disabled={!canScrollLeft}
        >
          <TablerArrowNarrowLeft className="h-6 w-6 text-gray-500" />
        </button>
        <button
          title="Suivant"
          className="relative z-20 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 disabled:opacity-50"
          onClick={() => scroll("right")}
          disabled={!canScrollRight}
        >
          <TablerArrowNarrowRight className="h-6 w-6 text-gray-500" />
        </button>
      </div>
      <div
        className="flex w-full overflow-x-scroll scroll-smooth py-10"
        style={{ scrollbarWidth: "none" }}
        ref={carouselRef}
        onScroll={checkScrollability}
      >
        <div
          className={cn(
            "flex flex-row justify-start gap-6",
            "ml-[5%] max-w-full lg:max-w-[90%] xl:max-w-[80%]",
          )}
        >
          {items.map((item, index) => (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.5, delay: 0.1 * index },
              }}
              key={`card-${index}`}
              className={`flex-shrink-0 ${
                index === items.length - 1 ? "pr-[5%]" : ""
              }`}
            >
              {item}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
