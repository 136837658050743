"use client";

import { motion } from "framer-motion";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { cn } from "@/utils/cn";

const forYouItems = [
  {
    title: "Vous voulez vous démarquer",
    description:
      "En tant qu'agence Next Generation, nous créons des sites ultra-performants avec Next.js. Vous obtenez un site qui se démarque par sa vitesse exceptionnelle, son référencement optimal et des animations fluides qui captent l'attention.",
    icon: "🚀",
  },
  {
    title: "Vous êtes pressé(e)",
    description:
      "Nous livrons votre landing page en 7 jours ou votre site complet en 14 jours. Notre processus optimisé et notre équipe dédiée garantissent des délais courts sans compromis sur la qualité.",
    icon: "⚡",
  },
  {
    title: "Vous privilégiez la communication",
    description:
      "Notre support 24/7 et la coordination en temps réel via WhatsApp vous permettent de suivre l'évolution de votre projet. Nous sommes là à chaque étape, de la conception à la mise en ligne.",
    icon: "🤝",
  },
  {
    title: "Vous aimez le contrôle",
    description:
      "Grâce à notre dashboard intégré et nos options de paiement flexibles (comptant ou mensuel), vous gardez le contrôle total sur votre projet et votre budget, en toute sérénité.",
    icon: "🎯",
  },
  {
    title: "Vous visez l'excellence",
    description:
      "La performance et l'optimisation SEO sont cruciales pour vous. Vous souhaitez un site qui se charge rapidement et qui convertit efficacement vos visiteurs. Notre expertise Next.js garantit des résultats exceptionnels.",
    icon: "💫",
  },
  {
    title: "Vous pensez croissance",
    description:
      "Du blog à l'e-commerce, en passant par les illustrations exclusives et les fonctionnalités avancées, vous voulez une solution évolutive qui grandira avec votre entreprise. Nous construisons pour votre succès futur.",
    icon: "📈",
  },
];

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      stiffness: 100,
    },
  },
};

const glowVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: [0, 0.5, 0],
    scale: [1, 1.2, 1],
    transition: {
      duration: 1.5,
      times: [0, 0.5, 1],
      repeat: 0,
    },
  },
};

export const ForYouSection = () => {
  return (
    <SectionLayout className="relative overflow-hidden py-32">
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-background to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-primary/5 via-transparent to-transparent opacity-50" />
      </div>

      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <TextSubtitle>Next Generation est fait pour vous si...</TextSubtitle>
        </motion.div>

        <div className="mt-16 grid gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-3">
          {forYouItems.map((item, index) => (
            <div
              key={item.title}
              className={cn(
                "group relative cursor-pointer overflow-hidden rounded-xl border p-6 sm:p-8",
                "border-gray-950 bg-black/95 backdrop-blur-3xl",
                "transition-all duration-500",
                "hover:border-primary/30 hover:bg-[#050505]",
                "hover:shadow-2xl hover:shadow-primary/20",
                "after:absolute after:inset-0 after:z-10 after:rounded-xl after:shadow-[inset_0_0_100px_rgba(0,0,0,0.9)]",
              )}
            >
              <div className="absolute inset-0 -z-10 translate-y-4 rounded-xl bg-primary/10 blur-3xl transition-all duration-500 group-hover:translate-y-0 group-hover:blur-2xl" />
              <div className="relative mb-8">
                <div className="transition-duration-300 absolute inset-0 -z-10 translate-y-1/2 bg-primary/20 blur-2xl group-hover:bg-primary/30" />
                <div className="text-6xl transition-transform duration-300 group-hover:scale-110">
                  {item.icon}
                </div>
              </div>

              <div className="relative mb-4">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={glowVariants}
                  className="absolute inset-0 bg-primary/10 blur-2xl"
                />
                <motion.h3
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={titleVariants}
                  custom={index}
                  className="relative text-xl font-bold text-white sm:text-2xl"
                >
                  <span className="relative">
                    <span className="relative z-10 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                      {item.title}
                    </span>
                    <motion.span
                      initial={{ width: "0%" }}
                      whileInView={{ width: "100%" }}
                      viewport={{ once: true }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1 + 0.3,
                        ease: "easeOut",
                      }}
                      className="absolute -bottom-1 left-0 h-[2px] bg-gradient-to-r from-primary via-primary/50 to-transparent"
                    />
                  </span>
                </motion.h3>
              </div>

              <p className="text-sm leading-relaxed text-gray-500 transition-colors duration-300 group-hover:text-gray-400 sm:text-base">
                {item.description}
              </p>

              <div className="absolute bottom-0 left-0 h-[2px] w-0 bg-gradient-to-r from-primary via-primary/50 to-transparent shadow-[0_0_10px_rgba(123,95,235,0.5)] transition-all duration-500 group-hover:w-full" />
            </div>
          ))}
        </div>
      </div>
    </SectionLayout>
  );
};
