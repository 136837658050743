"use client";

import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { PulsatingButton } from "@/components/common/button/ButtonPulsating";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { cn } from "@/utils/cn";

const keywords = [
  {
    text: "Création de site web",
    delay: 0.1,
    color: "from-blue-500 to-primary",
  },
  { text: "Agence web", delay: 0.2, color: "from-purple-500 to-primary" },
  { text: "Agence SEO", delay: 0.3, color: "from-indigo-500 to-primary" },
  {
    text: "Développement web sur-mesure",
    delay: 0.4,
    color: "from-violet-500 to-primary",
  },
  { text: "Next Generation", delay: 0.5, color: "from-primary to-primary/60" },
  {
    text: "NextGen France",
    delay: 0.6,
    color: "from-primary/80 to-primary/40",
  },
];

const features = [
  {
    title: "Expertise SEO Next.js",
    description:
      "Notre maîtrise de Next.js nous permet de créer des sites ultra-performants, parfaitement optimisés pour les moteurs de recherche.",
    icon: "🚀",
  },
  {
    title: "Stratégie sur-mesure",
    description:
      "Chaque projet bénéficie d'une approche personnalisée, adaptée à vos objectifs spécifiques et à votre secteur d'activité.",
    icon: "🎯",
  },
  {
    title: "Optimisation continue",
    description:
      "Nous analysons et optimisons en permanence votre positionnement pour maintenir votre avance sur la concurrence.",
    icon: "📈",
  },
];

export const SeoSection = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  return (
    <SectionLayout className="relative min-h-screen overflow-hidden py-32 sm:py-40">
      {/* Fond animé amélioré */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-gradient-to-b from-background via-primary/5 to-background" />
        <motion.div
          style={{ y, opacity }}
          className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-primary/20 via-transparent to-transparent"
        />
        {/* <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" /> */}
      </div>

      <div ref={containerRef} className="mx-auto max-w-6xl sm:px-6 lg:px-8">
        {/* En-tête animé */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <TextSubtitle className="relative inline-block">
            Dominez les résultats de recherche avec{" "}
            <span className="relative inline-block">
              <span className="bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                Next Generation
                <motion.span
                  initial={{ width: 0 }}
                  whileInView={{ width: "100%" }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="absolute -bottom-2 left-0 h-1 bg-gradient-to-r from-primary to-primary/60"
                />
              </span>
              <motion.span
                initial={{ scale: 1.2, opacity: 0 }}
                animate={{ scale: 1, opacity: 0.1 }}
                transition={{ duration: 2, repeat: Infinity }}
                className="absolute inset-0 -z-10 bg-primary blur-xl"
              />
            </span>
          </TextSubtitle>
        </motion.div>

        {/* Paragraphe d'introduction SEO */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mx-auto mt-12 max-w-3xl text-center text-lg text-gray-400 sm:mt-16 sm:text-xl"
        >
          L&apos;
          <span className="text-primary">agence Next Generation</span>{" "}
          révolutionne le{" "}
          <span className="text-primary">développement web</span> et le{" "}
          <span className="text-primary">référencement naturel</span>. Notre
          expertise unique en{" "}
          <span className="text-primary">création de sites web</span> nous
          permet de concevoir des plateformes performantes qui dominent
          naturellement les résultats de recherche.
        </motion.p>

        {/* Grille de mots-clés animée avec effets améliorés */}
        <div className="mt-24 grid grid-cols-1 gap-6 sm:mt-32 sm:grid-cols-2 sm:gap-8 md:grid-cols-3 lg:gap-10">
          {keywords.map(({ text, delay, color }, index) => (
            <motion.div
              key={text}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ delay, duration: 0.5 }}
              className={cn(
                "group relative cursor-pointer overflow-hidden rounded-xl border border-gray-50/[.1] bg-gray-50/[.01] p-6 sm:p-8",
                "transition-all duration-300 hover:border-primary/50 hover:bg-gray-50/[.05]",
                "backdrop-blur-sm",
              )}
            >
              <motion.div
                initial={{ x: "-100%" }}
                whileHover={{ x: "100%" }}
                transition={{ duration: 1 }}
                className={`absolute inset-0 -z-10 bg-gradient-to-r ${color} opacity-20`}
              />
              <div className="absolute inset-0 -z-10 translate-y-4 rounded-xl bg-primary/10 blur-xl transition-all duration-300 group-hover:translate-y-2 group-hover:blur-2xl" />
              <h3 className="text-xl font-semibold text-white">{text}</h3>
              <p className="mt-2 text-sm text-gray-400">
                Positionnez-vous sur Google avec notre expertise SEO
              </p>
            </motion.div>
          ))}
        </div>

        {/* Nouvelle section de caractéristiques */}
        <div className="mt-32 grid gap-8 sm:mt-40 sm:gap-10 md:grid-cols-3 lg:gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ delay: index * 0.2 }}
              className="relative rounded-lg border border-gray-50/[.1] p-6 sm:p-8"
            >
              <div className="mb-6 text-5xl sm:text-6xl">{feature.icon}</div>
              <h3 className="mb-4 text-xl font-semibold text-white sm:text-2xl">
                {feature.title}
              </h3>
              <p className="text-sm text-gray-400 sm:text-base">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>

        {/* Section de conversion améliorée */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="mt-32 rounded-2xl border border-gray-50/[.1] bg-gray-50/[.01] p-4 text-center backdrop-blur-sm ph:p-8 sm:mt-40 sm:p-12 lg:p-16"
        >
          <h2 className="text-2xl font-bold text-white sm:text-3xl lg:text-4xl">
            Prêt à dominer votre marché ?
          </h2>
          <p className="mx-auto mt-6 max-w-2xl text-gray-400 sm:mt-8 sm:text-lg">
            En tant qu&apos;
            <span className="text-primary">agence Next Generation</span>, nous
            combinons expertise technique et stratégie SEO pour propulser votre
            site web vers les sommets. Notre approche{" "}
            <span className="text-primary">sur-mesure</span> garantit des
            résultats durables.
          </p>
          <div className="mt-10 flex w-full justify-center sm:mt-12">
            <PulsatingButton calendly>
              Réserver un appel stratégique
            </PulsatingButton>
          </div>
        </motion.div>

        {/* Statistiques avec animations améliorées */}
        <div className="mt-32 grid grid-cols-2 gap-8 sm:mt-40 sm:grid-cols-4 sm:gap-12">
          {[
            { number: "1ère", text: "position visée sur Google" },
            { number: "100%", text: "référencement naturel" },
            { number: "+80%", text: "de trafic organique" },
            { number: "24/7", text: "monitoring SEO" },
          ].map(({ number, text }, index) => (
            <motion.div
              key={text}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 * index }}
              className="text-center"
            >
              <p className="text-2xl font-bold text-primary sm:text-3xl">
                {number}
              </p>
              <p className="mt-1 text-sm text-gray-400">{text}</p>
            </motion.div>
          ))}
        </div>

        {/* Nouvelle section de témoignage SEO */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-32 rounded-xl border border-gray-50/[.1] bg-gray-50/[.01] p-8 backdrop-blur-sm sm:mt-40 sm:p-12"
        >
          <blockquote className="relative">
            <span className="absolute -left-4 -top-4 text-6xl text-primary opacity-20 sm:text-7xl lg:text-8xl">
              &quot;
            </span>
            <p className="text-lg italic text-gray-400 sm:text-xl lg:text-2xl">
              Grâce à l&apos;expertise SEO de Next Generation, notre site est
              devenu la page 1 sur Google en seulement 4 mois.
            </p>
            <footer className="mt-6 text-right sm:mt-8">
              <cite className="text-sm text-primary sm:text-base">
                - Directrice de Mon dressing coloré
              </cite>
            </footer>
          </blockquote>
        </motion.div>
      </div>
    </SectionLayout>
  );
};
