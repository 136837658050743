import React from "react";

import { Avatar } from "@nextui-org/avatar";
import { Card } from "@nextui-org/card";
import { Link } from "@nextui-org/link";
import { cn } from "@/utils/cn";
import { Socials } from "@/utils/types/Socials";
import { BiGithub } from "@/assets/icons/iconify/bi-github";
import { BiLinkedin } from "@/assets/icons/iconify/bi-linkedin";
import { MdiWeb } from "@/assets/icons/iconify/mdi-web";

import { TeamMember } from "@/utils/Constants/aboutData";

type TeamMemberCardProps = React.HTMLAttributes<HTMLDivElement> &
  TeamMember & {
    compact?: boolean;
  };

type SocialBadgeProps = {
  social: string;
  type: Socials;
};

export const SocialBadge = ({ social, type }: SocialBadgeProps) => {
  const getIcon = (type: Socials) => {
    switch (type) {
      case Socials.LINKEDIN:
        return (
          <BiLinkedin className="w-5 text-white/80 transition-colors hover:text-white" />
        );
      case Socials.GITHUB:
        return (
          <BiGithub className="w-5 text-white/80 transition-colors hover:text-white" />
        );
      case Socials.PORTFOLIO:
        return (
          <MdiWeb className="w-5 text-white/80 transition-colors hover:text-white" />
        );
      default:
        return (
          <BiLinkedin className="w-5 text-white/80 transition-colors hover:text-white" />
        );
    }
  };

  return (
    <Link isExternal href={social}>
      {getIcon(type)}
    </Link>
  );
};

export const TeamMemberCard = React.forwardRef<
  HTMLDivElement,
  TeamMemberCardProps
>(
  (
    {
      children,
      avatar,
      name,
      role,
      social,
      className,
      compact = false,
      ...props
    },
    ref,
  ) => (
    <Card
      shadow="sm"
      className={`relative flex flex-col items-center justify-between rounded-xl bg-transparent ${compact ? "h-fit w-full ph:w-[400px]" : "h-full"}`}
    >
      <div
        className={`inset-0 rounded-xl bg-gradient-to-br from-[#7D7D95] to-[#4C4C95] p-[1px] ${compact ? "w-full" : ""}`}
      >
        <div
          ref={ref}
          className={cn(
            `flex items-center rounded-xl text-center ${compact ? "w-full flex-row gap-x-4 px-2 py-3 ph:px-8 ph:py-4" : "w-[300px] flex-col p-6"}`,
            "bg-[#393966]",

            className,
          )}
          {...props}
        >
          <Avatar
            className={`h-16 w-16 text-large ${compact ? "" : "mb-3"}`}
            src={typeof avatar === "string" ? avatar : avatar.src}
            alt={name}
          />
          <h3
            className={`text-lg font-semibold text-white ${compact ? "max-h-full max-w-[50%] text-left" : "mb-1"}`}
          >
            {name || children}
          </h3>
          <span
            className={`text-sm text-white/70 ${compact ? "hidden" : "mb-4"}`}
          >
            {role}
          </span>
          <div className="flex gap-4">
            {social?.linkedin && (
              <Link isExternal href={social.linkedin}>
                <BiLinkedin className="w-5 text-white/80 transition-colors hover:text-white" />
              </Link>
            )}
            {social?.github && (
              <Link isExternal href={social.github}>
                <BiGithub className="w-5 text-white/80 transition-colors hover:text-white" />
              </Link>
            )}
            {social?.portfolio && (
              <Link isExternal href={social.portfolio}>
                <MdiWeb className="w-6 text-white/80 transition-colors hover:text-white" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </Card>
  ),
);

TeamMemberCard.displayName = "TeamMemberCard";
