import MudoClub from "@/assets/images/logos-reviews/review-mudo-club.webp";
import JeffCoaching from "@/assets/images/logos-reviews/review-jeff-coaching.webp";
import AlexandraGrey from "@/assets/images/logos-reviews/review-alexandra-grey.webp";
import BeeDriver from "@/assets/images/logos-reviews/review-bee-driver.webp";
import MonDressingColore from "@/assets/images/logos-reviews/review-mon-dressing-colore.webp";

export const avatars = [
  {
    imageUrl: AlexandraGrey,
    profileUrl: " ",
  },
  {
    imageUrl: MudoClub,
    profileUrl: " ",
  },
  {
    imageUrl: JeffCoaching,
    profileUrl: " ",
  },
  {
    imageUrl: BeeDriver,
    profileUrl: " ",
  },
  {
    imageUrl: MonDressingColore,
    profileUrl: " ",
  },
];
