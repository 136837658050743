import { useState } from "react";
import { projects } from "@/utils/Constants/projects";
import { TextSubtitle } from "@/components/common/typography/TextSubtitle";
import { Spacer } from "@/components/custom/Spacer";
import { ProjectModal } from "@/components/common/modal/ModalProject";
import { Card } from "@/components/common/card/CardProjects";
import { Carousel } from "@/components/common/display/DisplayCarousel";
import { SectionLayout } from "@/components/layout/SectionLayout";
import { PrimaryButton } from "@/components/common/button/ButtonPrimary";

export const ProjectsSection = () => {
  const [expandedProject, setExpandedProject] = useState<string | null>(null);

  const handleImageClick = (key: string) => {
    setExpandedProject(key);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setExpandedProject(null);
    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
  };

  const selectedProject = projects.find(
    (project) => project.key === expandedProject,
  );

  return (
    <SectionLayout noPaddingX id="realisations" className="justify-center">
      <div className="flex w-full max-w-7xl flex-col px-2 text-center md:px-48">
        <TextSubtitle>
          Découvrez comment nous transformons vos idées en succès digitaux.
        </TextSubtitle>
        <Spacer y={2} />
        <p className="text-large text-default-500">
          Des résultats concrets qui parlent d&apos;eux-mêmes.
        </p>
      </div>

      <Carousel
        items={projects.map((project, index) => (
          <Card
            key={project.key}
            card={{
              src: project.mobileView.src,
              title: project.name,
              category: project.link.replace(/^https?:\/\//, "").split("/")[0],
            }}
            index={index}
            layout={false}
            onClick={() => handleImageClick(project.key)}
          />
        ))}
      />

      {expandedProject && selectedProject && (
        <ProjectModal
          onClose={handleClose}
          link={selectedProject.link}
          name={selectedProject.name}
          available={selectedProject.available}
        />
      )}
      {/* <PrimaryButton to={"/nos-realisations"}>
        Voir toutes les réalisations
      </PrimaryButton> */}
    </SectionLayout>
  );
};
