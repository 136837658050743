import { ICStar } from "@/assets/icons/iconify/ic-star";

interface RatingProps {
  score?: number;
  maxScore?: number;
  totalScore?: number;
}

export const RatingDisplay = ({ maxScore = 5 }: RatingProps) => {
  return (
    <div className="flex flex-col items-center md:items-start">
      <div className="flex">
        {[...Array(maxScore)].map((_, index) => (
          <ICStar key={index} className="h-6 w-6 text-primary" />
        ))}
      </div>
      <span className="text-white">+10 clients accompagnés et satisfaits</span>
    </div>
  );
};
