import JeffCoaching from "@/assets/images/logos-reviews/review-jeff-coaching.webp";
import BeeDriver from "@/assets/images/logos-reviews/review-bee-driver.webp";
import LaetitiaThao from "@/assets/images/logos-reviews/laetitia.webp";
import EcoHydrone from "@/assets/images/logos-reviews/eco-hydrone.webp";
import MonDressingColore from "@/assets/images/logos-reviews/review-mon-dressing-colore.webp";

export interface Review {
  name: string;
  role: string;
  content: string;
  image: any;
  rating: number;
}

export const reviewsData: Review[] = [
  {
    name: "Jeff Coaching Taekwondo",
    role: "Coach de Taekwondo",
    content:
      "Next Generation est une entreprise sérieuse, motivée et très investie. Ils ont défini avec moi un cahier des charges en fonction de mes attentes et de mes objectifs. Communication aisée, précise et agréable. Projet réalisé avec succès et dans les temps. En toute évidence, je recommande et les recontacterai pour une autre mission.",
    image: JeffCoaching,
    rating: 5,
  },
  // {
  //   name: "Totem, Liens et Chemins",
  //   role: "Association",
  //   content:
  //     "Nos interlocuteurs ont su être à l'écoute et patients. Le résultat est parfaitement celui que nous attendions. Nous recommandons.",
  //   image: TotemLiens,
  //   rating: 5,
  // },
  {
    name: "Bee Driver",
    role: "Auto-école",
    content:
      "Un travail rapide et de haute qualité, nous sommes très satisfaits.",
    image: BeeDriver,
    rating: 5,
  },
  {
    name: "Laetitia Thao",
    role: "Assistante Maternelle",
    content:
      "Je suis très contente du site web réalisé par l'agence Next Generation. Il convient à tous mes besoins en tant qu'assistante maternelle.",
    image: LaetitiaThao,
    rating: 5,
  },
  {
    name: "Eco Hydrone Cleaning",
    role: "Entreprise de nettoyage",
    content:
      "Très professionnel, très à l'écoute, très disponible et très compétent.",
    image: EcoHydrone,
    rating: 5,
  },
  {
    name: "Mon Dressing Colore",
    role: "Boutique de vêtements",
    content: "Merci, pour votre bienveillance et votre professionnalisme !",
    image: MonDressingColore,
    rating: 5,
  },
];
