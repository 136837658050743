import Image from "next/image";

import BeeDriver from "@/assets/images/clients/logo-bee-driver.webp";
import Taxi from "@/assets/images/clients/logo-taxi-beauchamp.png";
import Jeff from "@/assets/images/clients/jeff-coaching-taekwondo.webp";
import Totem from "@/assets/images/clients/totem33.webp";
import Laetitia from "@/assets/images/clients/logo-laetitia.webp";
import EcoHydroneCleaning from "@/assets/images/clients/eco-hydrone-cleaning.webp";
import WeblyInspiration from "@/assets/images/clients/logo-webly.webp";
import MonDressingColore from "@/assets/images/clients/logo-mon-dressing-colore.webp";
import AlexandraGrey from "@/assets/images/clients/logo-alexandra-grey.webp";
import MudoClub from "@/assets/images/clients/logo-mudo-club.png";
import EspaceCadet from "@/assets/images/clients/logo-espace-cadet.png";
import Innoveo from "@/assets/images/clients/logo-innoveo.png";

const logoSize = "max-h-[100px] max-w-[100px]";

export const logos = [
  {
    key: "mudo-club",
    logo: (
      <Image
        src={MudoClub}
        alt="Mudo Club"
        loading="lazy"
        className={logoSize}
      />
    ),
  },
  {
    key: "alexandra-grey",
    logo: (
      <Image
        src={AlexandraGrey}
        alt="Alexandra Grey"
        loading="lazy"
        className={logoSize}
      />
    ),
  },
  {
    key: "totem-33",
    logo: (
      <Image
        src={Totem}
        alt="Totem 33"
        loading="lazy"
        className="max-h-[90px]"
      />
    ),
  },
  {
    key: "bee-driver",
    logo: (
      <Image
        src={BeeDriver}
        alt="Bee Driver"
        loading="lazy"
        className={logoSize}
      />
    ),
  },
  {
    key: "jeff-coaching-taekwondo",
    logo: (
      <Image
        src={Jeff}
        alt="Jeff Coaching Taekwondo"
        className={logoSize}
        loading="lazy"
      />
    ),
  },
  {
    key: "laetitia",
    logo: (
      <Image
        src={Laetitia}
        className={logoSize}
        alt="Laetitia"
        loading="lazy"
      />
    ),
  },
  {
    key: "taxi-beauchamp",
    logo: (
      <Image
        src={Taxi}
        alt="Taxi Beauchamp"
        loading="lazy"
        className={`${logoSize} rounded-full`}
      />
    ),
  },
  {
    key: "eco-hydrone-cleaning",
    logo: (
      <Image
        src={EcoHydroneCleaning}
        className={logoSize}
        alt="Eco Hydrone Cleaning"
        loading="lazy"
      />
    ),
  },
  {
    key: "webly-inspiration",
    logo: (
      <Image
        src={WeblyInspiration}
        alt="Webly Inspiration"
        className={logoSize}
        loading="lazy"
      />
    ),
  },
  {
    key: "mon-dressing-colore",
    logo: (
      <Image
        src={MonDressingColore}
        alt="Mon dressing coloré"
        loading="lazy"
        className={`${logoSize} rounded-full`}
      />
    ),
  },
  {
    key: "espace-cadet",
    logo: (
      <Image
        src={EspaceCadet}
        alt="Espace Cadet"
        loading="lazy"
        className={`${logoSize} max-w-[55.8px]`}
      />
    ),
  },
  {
    key: "innoveo",
    logo: (
      <Image src={Innoveo} alt="Innoveo" loading="lazy" className={logoSize} />
    ),
  },
];
