export const useFaqData = () => {
  const FAQ = [
    {
      title: "Quels types de services proposez-vous exactement ?",
      content:
        "Notre agence se spécialise dans deux domaines principaux : la création de sites vitrines et la refonte de sites web existants. Que vous souhaitiez lancer votre présence en ligne ou moderniser un site existant, nous avons les solutions adaptées pour répondre à vos besoins.",
    },
    {
      title:
        "Quelles sont vos options de paiement et y a-t-il des engagements ?",
      content:
        "Nous offrons des options de paiement flexibles pour s'adapter à chaque client. Vous pouvez choisir de payer en plusieurs fois ou opter pour notre formule de paiement mensuel avec un acompte minimum, selon la formule choisie. Il n'y a pas d'engagement minimum. Notre objectif est de rendre nos services accessibles tout en vous offrant un contrôle total sur vos dépenses.",
    },
    {
      title: "Vos formules sont-elles adaptables à mes besoins spécifiques ?",
      content:
        "Oui, nos formules sont entièrement flexibles et personnalisables. Nous comprenons que chaque projet web est unique. Que vous ayez besoin de fonctionnalités spécifiques, d'un design sur mesure ou d'intégrations personnalisées, nous adaptons notre offre pour répondre à vos exigences. Chaque devis est élaboré sur mesure après une discussion approfondie de votre projet.",
    },
    {
      title:
        "Quels sont les avantages de choisir un paiement unique plutôt qu'un paiement mensuel ?",
      content:
        "Opter pour un paiement unique vous permet de bénéficier d'une réduction par rapport au montant total que vous auriez payé en choisissant la formule mensuelle. En plus de cette économie, vous n'avez plus à vous soucier des paiements récurrents, ce qui simplifie la gestion de votre budget. C'est une option avantageuse pour ceux qui préfèrent régler l'ensemble du projet d'un coup tout en profitant d'un tarif réduit.",
    },
    {
      title: "Combien de temps faut-il pour créer un site web ?",
      content:
        "La durée de création d'un site web dépend de sa complexité. Une landing page peut être réalisée en 3 jours à 1 semaine, tandis qu'un site vitrine plus complexe peut prendre entre 1 semaine et 2 semaines. Nous établissons un calendrier précis au début de chaque projet pour garantir le respect des délais.",
    },
    {
      title:
        "Puis-je faire un projet avec vous si je n'ai aucune maquette déjà prête ?",
      content:
        "Oui, nous vous accompagnons à toutes les étapes du projet, y compris la conception. Notre équipe de designers travaillera avec vous pour comprendre votre vision, votre marque et vos objectifs. Nous créerons des maquettes personnalisées basées sur vos besoins et préférences, que nous affinerons ensemble jusqu'à votre satisfaction complète.",
    },
    {
      title: "Mon site sera-t-il optimisé pour mobile ?",
      content:
        "Tous nos sites, qu'ils soient vitrines, e-commerce ou des refontes, sont conçus avec une approche 'mobile-first' et sont entièrement responsives. Cela garantit une expérience utilisateur optimale sur tous les appareils, des smartphones aux ordinateurs de bureau.",
    },
  ];

  return FAQ;
};
