import { Chip } from "@nextui-org/chip";
import { Loader } from "@/components/custom/Loader";
import { lazy, Suspense } from "react";

const DotLottieReact = lazy(() =>
  import("@lottiefiles/dotlottie-react").then((module) => ({
    default: module.DotLottieReact,
  })),
);

type Step = {
  titleKey: string;
  side: "left" | "right";
  lottieSrc?: string;
};

type ProcessCardProps = {
  step: Step;
  index: number;
  isWideScreen: boolean;
};

export const ProcessCard = ({
  step,
  index,
  isWideScreen,
}: ProcessCardProps) => {
  return (
    <div
      className={`w-10/12 p-3 pt-[50px] lg:p-6 ${
        isWideScreen ? "md:w-5/12" : "w-10/12"
      } ${isWideScreen && step.side === "left" ? "md:mr-8" : "ml-8"}`}
    >
      <div className="flex flex-col items-start">
        <Chip color="primary" className="mb-2 p-2 text-xl">
          {index + 1}.
        </Chip>
        <h3 className="mb-[50px] text-xl text-white">{step.titleKey}</h3>
      </div>
      {step.lottieSrc && (
        <div className="">
          <Suspense fallback={<Loader />}>
            <DotLottieReact src={step.lottieSrc} loop autoplay />
          </Suspense>
        </div>
      )}
    </div>
  );
};
