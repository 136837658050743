"use client";

import { cn } from "@/utils/cn";
import Image, { StaticImageData } from "next/image";

interface Avatar {
  imageUrl: StaticImageData;
  profileUrl: string;
}

interface AvatarCirclesProps {
  className?: string;
  numPeople?: number;
  avatarUrls: Avatar[];
}

export const AvatarCircles = ({
  // numPeople,
  className,
  avatarUrls,
}: AvatarCirclesProps) => {
  return (
    <a
      href="#reviews"
      className={cn("z-10 flex -space-x-4 rtl:space-x-reverse", className)}
    >
      <div className="flex -space-x-4">
        {avatarUrls.map((url, index) => (
          <Image
            key={index}
            className="h-10 w-10 rounded-full border-1 border-slate-600"
            src={url.imageUrl}
            width={40}
            height={40}
            alt={`Avatar ${index + 1}`}
          />
        ))}
      </div>
      {/* {numPeople && (
        <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-black text-center text-xs font-medium text-white hover:bg-gray-600 dark:border-gray-800 dark:bg-white dark:text-black">
          +{numPeople}
        </div>
      )} */}
    </a>
  );
};
