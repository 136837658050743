import type { PricingTier } from "../types/PricingTypes";
import { TiersEnum } from "../types/PricingTypes";

export const tiers: Array<PricingTier> = [
  {
    key: TiersEnum.Landing,
    nameKey: "Landing Page",
    features: [
      { labelKey: "Analyse concurrentielle" },
      { labelKey: "Copywriting stratégique" },
      { labelKey: "Design sur-mesure" },
      { labelKey: "Optimisation SEO" },
      { labelKey: "Optimisation performance" },
      { labelKey: "Animations" },
      { labelKey: "Dashboard intégré" },
      { labelKey: "Support h24/7j" },
      { labelKey: "Coordination en temps réel via WhatsApp" },
      { labelKey: "Livré en 7 jours" },
    ],
    buttonTextKey: "Lancer ma Landing Page",
    serviceParam: "landing",
    buttonColor: "primary",
    buttonVariant: "solid",
  },
  {
    key: TiersEnum.Website,
    nameKey: "Site Web Complet",
    features: [
      { labelKey: 'Tout de "Landing Page"' },
      { labelKey: "Suivi et conseil Marketing" },
      { labelKey: "Illustrations exclusives" },
      { labelKey: "Création Logo" },
      { labelKey: "Blog" },
      { labelKey: "E-commerce" },
      { labelKey: "Support h24/7j" },
      { labelKey: "Coordination en temps réel via WhatsApp" },
      { labelKey: "Livré en 14 jours" },
    ],
    mostPopular: true,
    buttonTextKey: "Lancer mon Site Web Complet",
    serviceParam: "website",
    buttonColor: "primary",
    buttonVariant: "solid",
  },
];
