import { cn } from "@/utils/cn";
import { Image } from "@nextui-org/react";
import { MovingBorder } from "@/components/custom/MovingBorder";
import Link from "next/link";

interface CardCaseStudyProps {
  clientLogo: string;
  before: number;
  after: number;
  problem: string;
  formulas: string[];
  className?: string;
}

export const CardCaseStudy = ({
  clientLogo,
  before,
  after,
  problem,
  formulas,
  className,
}: CardCaseStudyProps) => {
  const growthRate = ((after / before) * 100).toFixed(0);

  return (
    <div
      className={cn(
        "relative mt-12 w-full overflow-hidden rounded-3xl p-[1px]",
        className,
      )}
    >
      {/* Background gradient */}
      <div className="absolute inset-0 rounded-3xl bg-gradient-to-br from-[#7D7D95] to-[#4C4C95]" />

      {/* Moving border effect */}
      <div className="absolute inset-0">
        <MovingBorder rx="24px" ry="24px" initialProgress={0}>
          <div className="h-32 w-32 bg-[radial-gradient(#7b5feb_60%,transparent_70%)] opacity-[1]" />
        </MovingBorder>
      </div>

      {/* Main content */}
      <div className="relative h-full rounded-3xl bg-[#393966] p-8 backdrop-blur-xl">
        <div className="flex flex-col gap-8 md:flex-row lg:gap-12">
          {/* Logo section */}
          <div className="flex-shrink-0 md:w-64">
            <div className="relative aspect-square w-full overflow-hidden rounded-2xl bg-white/10 p-6">
              <Image
                src={clientLogo}
                alt="Client logo"
                className="h-full w-full object-contain"
              />
            </div>
          </div>

          {/* Content section */}
          <div className="flex flex-1 flex-col gap-8">
            {/* Header with title */}
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold text-white">
                La situation
              </h3>
              <p className="leading-relaxed text-gray-300">{problem}</p>
            </div>

            {/* Metrics section */}
            <div className="flex flex-wrap items-start gap-x-16 gap-y-6">
              {/* Before metric */}
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-300">Avant</p>
                <div className="flex h-11 items-end gap-2">
                  <div className="rounded-2xl bg-red-500/20 px-4 py-2 backdrop-blur-sm">
                    <span className="text-xl font-semibold text-red-300">
                      {before.toLocaleString()}€
                    </span>
                  </div>
                  <span className="pb-2 text-xs text-gray-400">
                    Sur les 4 derniers mois avant nous
                  </span>
                </div>
              </div>

              {/* After metric */}
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-300">Après</p>
                <div className="flex h-11 items-end gap-2">
                  <div className="rounded-2xl bg-emerald-500/20 px-4 py-2 backdrop-blur-sm">
                    <span className="text-xl font-semibold text-emerald-300">
                      {after.toLocaleString()}€
                    </span>
                  </div>
                  <span className="pb-2 text-xs text-gray-400">
                    Sur les 4 derniers mois après nous
                  </span>
                </div>
              </div>

              {/* Growth metric */}
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-300">Croissance</p>
                <div className="flex h-11 items-center gap-2">
                  <div className="h-2 w-2 rounded-full bg-blue-400" />
                  <span className="text-xl font-semibold text-white">
                    +{growthRate}%
                  </span>
                </div>
              </div>
            </div>

            {/* Solutions section */}
            <div className="space-y-3 pt-4">
              <h4 className="text-base text-gray-300">Solutions appliquées</h4>
              <div className="flex flex-wrap gap-2">
                {formulas.map((formula, index) => (
                  <span
                    key={index}
                    className="rounded-xl bg-white/10 px-4 py-2 text-sm text-gray-300 backdrop-blur-sm"
                  >
                    <Link href="/#tarifs">{formula}</Link>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
