import React, { useState } from "react";

import { Image, ImageProps } from "@nextui-org/image";

import { cn } from "@/utils/cn";

export const BlurImage = React.memo(
  ({ height, width, src, className, alt, ...rest }: ImageProps) => {
    const [isLoading, setLoading] = useState(true);

    return (
      <Image
        className={cn(
          "transition duration-300",
          isLoading ? "blur-sm" : "blur-0",
          className,
        )}
        onLoad={() => setLoading(false)}
        src={src}
        width={width}
        height={height}
        loading="lazy"
        alt={alt || "Background of a beautiful view"}
        {...(rest as object)}
      />
    );
  },
);

BlurImage.displayName = "BlurImage";
