import React from "react";
import { motion } from "framer-motion";
import { BlurImage } from "@/components/common/card/CardProjectsBlurImage";

type Card = {
  src: string;
  title: string;
  category: string;
};

export const Card = React.memo(
  ({
    card,
    layout = false,
    onClick,
  }: {
    card: Card;
    index: number;
    layout?: boolean;
    onClick?: () => void;
  }) => {
    return (
      <motion.button
        layoutId={layout ? `card-${card.title}` : undefined}
        onClick={onClick}
        className="relative z-10 flex h-[400px] w-[280px] flex-col items-start justify-start overflow-hidden rounded-3xl bg-gray-100 dark:bg-neutral-900 sm:h-[450px] sm:w-[320px] md:h-[500px] md:w-[350px] lg:h-[550px] lg:w-[380px]"
        whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
      >
        <div className="absolute h-full w-full">
          <BlurImage
            src={card.src}
            alt={card.title}
            className="inset-0 z-10 object-cover transition-all duration-300 ease-in-out hover:blur-[0px]"
          />
        </div>
        <div className="pointer-events-none absolute inset-x-0 top-0 z-30 h-full bg-gradient-to-b from-black/50 via-transparent to-transparent" />
        <div className="relative z-40 p-8">
          <motion.p
            layoutId={layout ? `category-${card.category}` : undefined}
            className="text-left font-sans text-sm font-medium text-white md:text-base"
          >
            {card.category}
          </motion.p>
          <motion.p
            layoutId={layout ? `title-${card.title}` : undefined}
            className="mt-2 max-w-xs text-left font-sans text-xl font-semibold text-white [text-wrap:balance] md:text-3xl"
          >
            {card.title}
          </motion.p>
        </div>
        <div className="absolute inset-0 z-20 bg-black opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-10" />
      </motion.button>
    );
  },
);

Card.displayName = "Card";
