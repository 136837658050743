import type { ButtonProps } from "@nextui-org/button";

export enum FrequencyEnum {
  Monthly = "monthly",
  OneTime = "one_time",
}

export enum TiersEnum {
  Landing = "landing",
  Website = "website",
  Refonte = "refonte",
  Startup = "startup",
}

export type Frequency = {
  key: FrequencyEnum;
  labelKey: string;
  priceSuffixKey: string;
};

export type Feature = {
  labelKey: string;
  isAdditional?: boolean;
};

export type PricingTier = {
  key: TiersEnum;
  nameKey: string;
  features: Feature[];
  bonus?: { labelKey: string; price: { [key in FrequencyEnum]: string } }[];
  mostPopular?: boolean;
  buttonTextKey: string;
  buttonColor?: ButtonProps["color"];
  buttonVariant?: ButtonProps["variant"];
  serviceParam: string;
};
