"use client";

import React from "react";
import {
  motion,
  type AnimationProps,
  type HTMLMotionProps,
} from "framer-motion";

import { cn } from "@/utils/cn";

const animationProps = {
  initial: { "--x": "100%", scale: 0.8 },
  animate: { "--x": "-100%", scale: 1 },
  whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 1,
    type: "spring",
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: "spring",
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
} as AnimationProps;

interface ShinyCheapProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
  className?: string;
}

export const ShinyCheap = React.forwardRef<HTMLDivElement, ShinyCheapProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <motion.div
        ref={ref}
        {...animationProps}
        {...props}
        className={cn(
          "relative rounded-xl bg-[#2a2754] px-6 py-2 font-medium shadow-[0_10px_20px_-5px_rgba(0,0,0,0.4)] transition-all duration-300 ease-in-out hover:shadow-[0_15px_25px_-5px_rgba(0,0,0,0.4)]",
          className,
        )}
      >
        <span className="relative block size-full text-sm uppercase tracking-wide text-white">
          {children}
        </span>
        <span
          style={{
            mask: "linear-gradient(rgb(0,0,0), rgb(0,0,0)) content-box,linear-gradient(rgb(0,0,0), rgb(0,0,0))",
            maskComposite: "exclude",
          }}
          className="absolute inset-0 z-10 block rounded-[inherit] bg-[linear-gradient(-75deg,#7b5feb20_calc(var(--x)+20%),#7b5feb80_calc(var(--x)+25%),#7b5feb20_calc(var(--x)+100%))] p-px"
        ></span>
      </motion.div>
    );
  },
);

ShinyCheap.displayName = "ShinyCheap";

