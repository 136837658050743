import React from "react";
import { cn } from "@/utils/cn";
import { MovingBorder } from "@/components/custom/MovingBorder";

interface ExperienceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className?: string;
  delay?: number;
}

export const CardImpact = ({
  icon: Icon,
  title,
  description,
  className,
  delay = 0,
}: ExperienceCardProps) => {
  return (
    <div
      className={cn(
        "relative min-h-[250px] overflow-hidden rounded-3xl bg-transparent p-[1px]",
        className,
      )}
    >
      <div className="absolute inset-0 rounded-3xl bg-gradient-to-br from-[#7D7D95] to-[#4C4C95]" />

      <div className="absolute inset-0">
        <MovingBorder rx="24px" ry="24px" initialProgress={delay}>
          <div className="h-32 w-32 bg-[radial-gradient(#7b5feb_60%,transparent_70%)] opacity-[1]" />
        </MovingBorder>
      </div>

      <div className="relative h-full rounded-3xl bg-[#393966] p-6 backdrop-blur-xl">
        <div className="flex h-full flex-col gap-6">
          <div className="size-20 shrink-0">{Icon}</div>
          <div className="flex flex-col gap-2 text-left">
            <h3 className="text-xl font-semibold text-white">{title}</h3>
            <p className="text-sm text-gray-400">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
