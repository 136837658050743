import { StaticImageData } from "next/image";

// LOGOS
import logo_beedriver from "../../assets/images/clients/logo-bee-driver.webp";
import logo_laetitia_thao from "../../assets/images/clients/logo-laetitia.webp";
import logo_jeff_taekwondo from "../../assets/images/clients/jeff-coaching-taekwondo.webp";
import logo_taxi_beauchamp from "../../assets/images/clients/taxi_beauchamp.webp";
import logo_webly_inspiration from "../../assets/images/clients/logo-webly.webp";

// POUR LES SCREEN DES IMAGES DES PROJETS MOBILE, TAILLES --> WIDTH: 1667px, HEIGHT: 1000px
import beedriver from "../../assets/images/projects/mobile/bee_driver-mobile.webp";
import taxi_beauchamp from "../../assets/images/projects/mobile/taxi_beauchamp-mobile.webp";
import jeff_taekwondo from "../../assets/images/projects/mobile/jeff_coaching_taekwondo-mobile.webp";
// import eco_hydrone from "../../assets/images/projects/mobile/eco_hydrone.webp";
import laetitia_thao from "../../assets/images/projects/mobile/laetitia_thao-mobile.webp";
import webly_inspiration from "../../assets/images/projects/mobile/webly_inspiration-mobile.webp";

// POUR LES SCREEN DES IMAGES DES PROJETS DESKTOP, TAILLES --> WIDTH: 1440px, HEIGHT: 930px
import beedriver_desktop from "../../assets/images/projects/desktop/beedriver.webp";
import jeff_taekwondo_desktop from "../../assets/images/projects/desktop/jeff-coaching.webp";
import taxi_beauchamp_desktop from "../../assets/images/projects/desktop/taxi-beauchamp.webp";
import laetitia_thao_desktop from "../../assets/images/projects/desktop/laetitia.webp";
import webly_inspiration_desktop from "../../assets/images/projects/desktop/webly.webp";

type ProjectType = {
  key: string;
  mobileView: StaticImageData;
  desktopView: StaticImageData;
  link: string;
  name: string;
  available: boolean;
  labels: string[];
  logo: StaticImageData;
};

export const projects: ProjectType[] = [
  {
    key: "6",
    mobileView: beedriver,
    link: "https://beedriver.fr/",
    name: "BEE DRIVER",
    available: true,
    labels: ["project_type_auto_ecole"],
    logo: logo_beedriver,
    desktopView: beedriver_desktop,
  },
  // {
  //   key: "5",
  //   mobileView: webly_inspiration,
  //   link: "https://webly-inspiration.vercel.app/",
  //   name: "WEBLY INSPIRATION",
  //   available: true,
  //   labels: ["project_type_veille_technologique"],
  //   logo: logo_webly_inspiration,
  //   desktopView: webly_inspiration_desktop,
  // },
  {
    key: "4",
    mobileView: taxi_beauchamp,
    link: "https://taxi-beauchamp.fr/",
    name: "TAXI BEAUCHAMP",
    available: true,
    labels: ["project_type_taxi"],
    logo: logo_taxi_beauchamp,
    desktopView: taxi_beauchamp_desktop,
  },
  {
    key: "3",
    mobileView: jeff_taekwondo,
    link: "https://jeff-coaching-taekwondo.fr/",
    name: "JEFF TAEKWONDO",
    available: true,
    labels: ["project_type_coach_sportif"],
    logo: logo_jeff_taekwondo,
    desktopView: jeff_taekwondo_desktop,
  },
  {
    key: "2",
    mobileView: laetitia_thao,
    link: "https://laetitia-thao.next-generation.dev/",
    name: "LAETITIA THAO",
    available: true,
    labels: ["project_type_assistante_maternelle"],
    logo: logo_laetitia_thao,
    desktopView: laetitia_thao_desktop,
  },
  // {
  //   key: "1",
  //   mobileView: eco_hydrone,
  //   link: "https://eco-hydrone-cleaning.next-generation.dev/",
  //   name: "ECO HYDRONE",
  //   available: true,
  //   labels: [],
  //   logo: logo_eco_hydrone,
  //   desktopView: eco_hydrone_desktop,
  // },
];
