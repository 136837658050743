import React from "react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { cn } from "@/utils/cn";

type ScrollingBannerProps = {
  isReverse?: boolean;
  showShadow?: boolean;
  isVertical?: boolean;
  gap?: string;
  duration?: number;
  className?: string;
  children?: React.ReactNode;
};

export const ScrollingBanner = React.forwardRef<
  HTMLDivElement,
  ScrollingBannerProps
>(
  (
    {
      className,
      isReverse,
      isVertical = false,
      gap = "200px",
      showShadow = true,
      duration = 100,
      children,
      ...props
    },
    ref,
  ) => {
    const baseClasses = "flex";
    const verticalClasses = isVertical
      ? ""
      : "w-full overflow-y-hidden overflow-x-hidden max-h-[calc(100vh_-_200px)]";

    const classNames = cn(baseClasses, verticalClasses, className);

    return (
      <ScrollShadow
        {...props}
        {...{
          isEnabled: showShadow,
          offset: -20,
          size: 200,
          orientation: isVertical ? "vertical" : "horizontal",
          visibility: "both",
        }}
        ref={ref}
        className={classNames}
        style={{
          ["--gap" as string]: gap,
          ["--duration" as string]: `${duration}s`,
        }}
      >
        <div
          className={cn(
            "flex w-max items-center justify-center gap-[--gap] px-32",
            {
              "flex-col": isVertical,
              "h-full": isVertical,
              "animate-scrolling-banner": !isVertical,
              "animate-scrolling-banner-vertical": isVertical,
              "[animation-direction:reverse]": isReverse,
            },
          )}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child as React.ReactElement, {
              className:
                "w-[100px] grayscale hover:grayscale-0 transition-all duration-300 flex items-center justify-center",
            }),
          )}
        </div>
      </ScrollShadow>
    );
  },
);

ScrollingBanner.displayName = "ScrollingBanner";
