import { MdiMagnify } from "@/assets/icons/iconify/mdi-magnify";
import { MdiFinance } from "@/assets/icons/iconify/mdi-finance";
import { MdiLightbulbOutline } from "@/assets/icons/iconify/mdi-lightbulb-outline";
import { MdiFlashOutline } from "@/assets/icons/iconify/mdi-flash-outline";

export type ImpactData = {
  title: string;
  content: string;
  icon: (props: { color?: string }) => JSX.Element;
};

export const useImpactData = () => {
  const impactData: ImpactData[] = [
    {
      title: "Impact financier",
      content:
        "Maximisez vos revenus avec des solutions qui augmentent vos ventes dès le premier mois.",
      icon: MdiFinance,
    },
    {
      title: "Top des recherches Google",
      content:
        "Boostez votre visibilité pour apparaître en tête et attirer des clients qualifiés.",
      icon: MdiMagnify,
    },
    {
      title: "Clé en main",
      content:
        "Profitez d’un service complet : branding, design, développement et hébergement. On s'occupe de tout pour vous.",
      icon: MdiFlashOutline,
    },
    {
      title: "Se démarquer de la concurrence",
      content:
        "Un design unique et une expérience mémorable pour marquer les esprits.",
      icon: MdiLightbulbOutline,
    },
  ];

  return { impactData };
};
