"use client";

import { useCalendlyModal } from "@/hooks/use-calendly-modal";
import { cn } from "@/utils/cn";
import { PopupModal } from "react-calendly";
import { useEffect, useState } from "react";

interface PulsatingButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  pulseColor?: string;
  duration?: string;
  calendly?: boolean;
  pulsating?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  colorClassName?: string;
}

export function PulsatingButton({
  className,
  children,
  pulseColor = "#6268F1",
  duration = "1.5s",
  calendly = false,
  pulsating = false,
  onClick,
  colorClassName = "bg-black text-white hover:bg-black hover:text-white",
  ...props
}: PulsatingButtonProps) {
  const { isOpen, openModal, closeModal } = useCalendlyModal();
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setRootElement(document.getElementById("root"));
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (calendly) {
      openModal();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={cn(
          "relative flex cursor-pointer items-center justify-center rounded-lg px-6 py-2 text-center",
          colorClassName,
          className,
        )}
        style={
          {
            "--pulse-color": pulseColor,
            "--duration": duration,
          } as React.CSSProperties
        }
        {...props}
      >
        <div className="relative z-10 flex items-center justify-center gap-2">
          {children}
        </div>
        {pulsating && (
          <div className="absolute left-1/2 top-1/2 size-full -translate-x-1/2 -translate-y-1/2 animate-pulse rounded-lg bg-inherit" />
        )}
      </button>
      {rootElement && (
        <PopupModal
          url="https://calendly.com/contact-next-generation/30min?hide_gdpr_banner=1"
          onModalClose={closeModal}
          open={isOpen}
          rootElement={rootElement}
        />
      )}
    </>
  );
}
